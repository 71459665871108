@font-face {
    font-family: 'Generator';
    src: local('Generator Medium'), local('Generator-Medium'), url('Generator-Medium.woff2') format('woff2'), url('Generator-Medium.woff') format('woff'), url('Generator-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Generator';
    src: local('Generator Bold'), local('Generator-Bold'), url('Generator-Bold.woff2') format('woff2'), url('Generator-Bold.woff') format('woff'), url('Generator-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Generator';
    src: local('Generator Ultra Bold'), local('Generator-UltraBold'), url('Generator-UltraBold.woff2') format('woff2'), url('Generator-UltraBold.woff') format('woff'), url('Generator-UltraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}
